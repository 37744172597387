import { css, cx } from "@onbeam/styled-system/css";
import { customScrollbar } from "@onbeam/styled-system/recipes";
import { flex, vstack } from "@onbeam/styled-system/patterns";
import { Card, PixelBackground, AppBackground } from "@onbeam/ui";

import "./DocumentLayout.css";
import "./index.css";

export const DocumentLayout = ({ children }) => (
  <div
    className={cx(
      customScrollbar(),
      vstack({
        minH: "[100vh]",
      })
    )}
  >
    <AppBackground />
    <main
      className={flex({
        w: "full",
        h: "[calc(100vh - token(spacing.16))]",
        top: "16",
        overflow: "hidden",
        pos: "absolute",
        justify: "center",
      })}
    >
      <Card
        size="md"
        className={css({
          position: "absolute",
          top: "0",
          maxW: "[52rem]",

          "@media (max-width: 52rem)": {
            rounded: "none",
          },
        })}
      >
        <PixelBackground>
          <div
            className={css({
              "& div": {
                display: "flex",
                flexDirection: "column",
                gap: "3",
                overflowY: "scroll",
                maxH: "[calc(100vh - token(spacing.20) - token(spacing.2))]",
                p: "6",
              },
            })}
          >
            {children}
          </div>
        </PixelBackground>
      </Card>
    </main>
  </div>
);
