import { mdxComponents } from "@onbeam/ui";
import React, { lazy, Suspense } from "react";
import { importMDX } from "mdx.macro";

const PrivacyPolicyDocument = lazy(() => importMDX("./privacy-policy.mdx"));

export const PrivacyPolicy = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <PrivacyPolicyDocument components={mdxComponents} />
  </Suspense>
);
