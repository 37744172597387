import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "../Images/close-icon.svg";
import SwapImage from "../AppImages/Swap.gif";
import BridgeImage from "../AppImages/Bridge.gif";
import SphereImage from "../AppImages/Sphere.gif";
import DocsImage from "../AppImages/Docs.gif";
import WalletImage from "../AppImages/App.gif";
import HubImage from "../AppImages/Hub.gif";
import WallpaperOne from "../Wallpapers/One.webp";
import WallpaperTwo from "../Wallpapers/Two.webp";
import WallpaperThree from "../Wallpapers/Three.webp";
import WallpaperFour from "../Wallpapers/Four.webp";
import WallpaperFive from "../Wallpapers/Five.webp";
import WallpaperSix from "../Wallpapers/Six.webp";
import HomeImage from "../Images/home.png";
import FauxWindow from "../FauxWindow/FauxWindow";
import SnakeGame from "../SnakeGame/SnakeGame";
import MenuBar from "../MenuBar/MenuBar";
import { useCloseOnOutsideClick } from "../hooks/useCloseOnOutsideClick";
import { Loader } from "../Loader/index";
import { TermsOfUse } from "../TermsOfUse";
import { PrivacyPolicy } from "../PrivacyPolicy";
import { About } from "../About/index";
import { MenuItem } from "../MenuItem/index";

import "./RootPage.css";

import {
  TermsIcon,
  PrivacyIcon,
  StatusIcon,
  QuestionMarkIcon,
  BeamOsIcon,
} from "../Icons/index";

const apps = [
  {
    name: "beam companion app",
    description: "the beam companion app",
    url: "https://apps.apple.com/pl/app/beam-companion/id6467641736 ",
    dockImage: "/assets/images/dock-companion.png",
    image: WalletImage,
    disableIframe: true,
  },
  {
    name: "sphere market",
    description: "beam's native marketplace",
    url: "https://sphere.market",
    dockImage: "/assets/images/dock-sphere.png",
    image: SphereImage,
  },
  {
    name: "beam hub",
    description: "everything gaming in one hub",
    url: "https://hub.onbeam.com",
    dockImage: "/assets/images/dock-hub.png",
    image: HubImage,
  },
  {
    name: "beam swap",
    description: "trade in game currencies",
    url: "https://swap.onbeam.com",
    dockImage: "/assets/images/dock-swap.png",
    image: SwapImage,
  },
  {
    name: "dev docs",
    description: "documentation for developers",
    url: "https://docs.onbeam.com/chain",
    dockImage: "/assets/images/dock-sdk.png",
    image: DocsImage,
  },
  {
    name: "beam bridge",
    description: "move assets to beam",
    url: "https://bridge.onbeam.com",
    dockImage: "/assets/images/dock-bridge.png",
    image: BridgeImage,
  },
];

const WALLPAPERS = [
  WallpaperOne,
  WallpaperTwo,
  WallpaperThree,
  WallpaperFour,
  WallpaperFive,
  WallpaperSix,
];

export const RootPage = () => {
  const [iframeUrl, setIframeUrl] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const iframeRef = useRef(null);

  const settingsModalRef = useRef(null);

  const [walletAddress, setWalletAddress] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWallpaper, setSelectedWallpaper] = useState(null);

  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const [settings, setSettings] = useState({
    wallpaper: WallpaperOne,
    launchStrategy: "newTab",
    username: "anon",
  });

  const contextMenuRef = useRef(null);
  const startButtonRef = useRef(null);

  useCloseOnOutsideClick(() => setIsModalOpen(false), [settingsModalRef]);

  useEffect(() => {
    const localStorageSettings = localStorage.getItem("settings");

    if (localStorageSettings) {
      setSettings(JSON.parse(localStorageSettings));
    }
  }, []);

  const handleSettingsConfirm = () => {
    localStorage.setItem("settings", JSON.stringify(settings));

    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target) &&
        !startButtonRef.current.contains(event.target)
      ) {
        setIsContextMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isFauxWindowOpen, setIsFauxWindowOpen] = useState(false);

  const handleAboutBeamClick = () => {
    if (!isFauxWindowOpen) {
      setIsFauxWindowOpen(true);
    }
  };

  const [isSnakeGameOpen, setIsSnakeGameOpen] = useState(false);

  const handleSnakeGameClick = () => {
    if (!isSnakeGameOpen) {
      setIsSnakeGameOpen(true);
    }
    setIsContextMenuOpen(false); // Close the start menu
  };

  const launchApp = (url) => {
    if (
      settings.launchStrategy === "newTab" ||
      apps.find((app) => app.url === url)?.disableIframe
    ) {
      window.open(url, "_blank");
    } else {
      setIframeUrl(url);
    }
  };

  const closeIframe = () => {
    setIframeUrl(null);
  };

  const copyIframeUrl = () => {
    navigator.clipboard.writeText(iframeUrl);
  };

  useEffect(() => {
    const checkConnectedWallet = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        }
      }
    };

    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        console.log(
          "MetaMask is locked or the user has not connected any accounts"
        );
        setWalletAddress(null);
      } else if (accounts[0] !== walletAddress) {
        setWalletAddress(accounts[0]);
      }
    };

    checkConnectedWallet();

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, [walletAddress]);

  console.log(walletAddress);

  return (
    <div className="app-wrapper">
      <img className="app-background" src={settings.wallpaper}></img>

      <Loader visible={showLoader} onClose={() => setShowLoader(false)} />
      <div className="crt-scanlines">
        <div className="mask-container">
          {/* <img className="img1" src={Terminal} />
    <img className="img2" src={MaskTwo} /> */}
        </div>
        <MenuBar name={settings.username} />
        <div className="app-container-center">
          <div className="app-container">
            {apps.map((app, index) => (
              <div key={index} className="app-card">
                <div className="image-container">
                  {app.image && (
                    <img src={app.image} alt={app.name} className="app-image" />
                  )}
                  <button
                    className="launch-button launch-button--desktop"
                    onClick={() => launchApp(app.url)}
                  >
                    Launch
                  </button>
                </div>
                <div className="app-meta">
                  <div className="app-meta--text">
                    <h2 className="app-name heading--weight-normal">
                      {app.name}
                    </h2>
                    <p className="app-description">{app.description}</p>
                  </div>

                  <button
                    className="launch-button launch-button--mobile"
                    onClick={() => launchApp(app.url)}
                  >
                    Launch
                  </button>
                </div>
              </div>
            ))}

            {iframeUrl && (
              <div className="iframe-container">
                <div className="iframe-toolbar">
                  <a href={iframeUrl} target="_blank" rel="noreferrer">
                    <button className="share-button">Open in new tab</button>
                  </a>
                  <button className="close-button" onClick={closeIframe}>
                    <img src={CloseIcon} />
                  </button>
                </div>
                <iframe
                  src={iframeUrl}
                  title="App Frame"
                  className="app-iframe"
                  ref={iframeRef}
                  seamless
                ></iframe>
              </div>
            )}
            <div className="dock">
              <div className="dock-buttons">
                <button
                  className="start-button"
                  onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
                  ref={startButtonRef}
                >
                  <img
                    src={HomeImage}
                    alt="Start Button"
                    width="25"
                    height="25"
                  />
                </button>

                {isContextMenuOpen && (
                  <div className="context-menu" ref={contextMenuRef}>
                    <div>
                      <MenuItem
                        onClick={handleAboutBeamClick}
                        icon={<QuestionMarkIcon />}
                      >
                        about Beam
                      </MenuItem>
                      <MenuItem icon={<StatusIcon />}>
                        <a
                          href="https://subnets.avax.network/stats"
                          target="_blank"
                        >
                          subnet status
                        </a>
                      </MenuItem>
                      <MenuItem
                        icon={<TermsIcon />}
                        onClick={() => setIsTermsAndConditionsOpen(true)}
                      >
                        terms and conditions
                      </MenuItem>
                      <MenuItem
                        icon={<PrivacyIcon />}
                        onClick={() => setIsPrivacyPolicyOpen(true)}
                      >
                        privacy policy
                      </MenuItem>
                      <MenuItem icon={<PrivacyIcon />}>
                        <a
                          href="https://support.onbeam.com/hc/en-us/categories/14560196521501-BeamOS"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          support
                        </a>
                      </MenuItem>
                    </div>
                  </div>
                )}
              </div>
              <div className="spacer"></div>{" "}
              {/* This will push the icons and buttons apart */}
              <div className="dock-icons">
                {apps.map((app, index) => (
                  <div
                    key={index}
                    className="dock-item"
                    onClick={() => launchApp(app.url)}
                  >
                    <img
                      src={app.dockImage}
                      alt={`${app.name} dock icon`}
                      className="dock-image"
                    />
                    {iframeUrl === app.url && (
                      <div className="active-indicator"></div>
                    )}
                  </div>
                ))}
              </div>
              <div className="dock-buttons">
                <button
                  className="dock-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                >
                  Settings
                </button>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="settings" ref={settingsModalRef}>
            <h3>App Launcher Settings</h3>
            <div className="open-option">
              <label>
                <input
                  type="radio"
                  value="newTab"
                  checked={settings.launchStrategy === "newTab"}
                  onChange={(e) =>
                    setSettings((currentSettings) => ({
                      ...currentSettings,
                      launchStrategy: e.target.value,
                    }))
                  }
                />
                Launch apps in new tab
              </label>
              <label>
                <input
                  type="radio"
                  value="iframe"
                  checked={settings.launchStrategy === "iframe"}
                  onChange={(e) =>
                    setSettings((currentSettings) => ({
                      ...currentSettings,
                      launchStrategy: e.target.value,
                    }))
                  }
                />
                Launch apps in beamOS
              </label>
            </div>
            <div className="name-setting">
              <h3>Set Your Name</h3>
              <input
                type="text"
                className="username-input"
                value={settings.username}
                onChange={(e) =>
                  setSettings((currentSettings) => ({
                    ...currentSettings,
                    username: e.target.value,
                  }))
                }
                placeholder="Enter your name..."
              />
            </div>
            <h3>Background Wallpaper</h3>
            <div className="wallpaper-container">
              {WALLPAPERS.map((wallpaper) => (
                <div
                  key={wallpaper}
                  className={`wallpaper ${
                    settings.wallpaper === wallpaper ? "wallpaper__active" : ""
                  }`}
                  style={{
                    backgroundImage: `url(${wallpaper})`,
                  }}
                  onClick={() =>
                    setSettings((currentSettings) => ({
                      ...currentSettings,
                      wallpaper,
                    }))
                  }
                ></div>
              ))}
            </div>
            <button className="confirm-button" onClick={handleSettingsConfirm}>
              confirm
            </button>

            <div className="caption settings-caption">
              <BeamOsIcon />
            </div>
          </div>
        )}

        {isFauxWindowOpen && (
          <FauxWindow onClose={() => setIsFauxWindowOpen(false)}>
            <pre
              style={{
                maxWidth: "800px",
                whiteSpace: "pre-wrap",
              }}
            >
              <About />
            </pre>
          </FauxWindow>
        )}

        {isSnakeGameOpen && (
          <FauxWindow onClose={() => setIsSnakeGameOpen(false)}>
            <SnakeGame />
          </FauxWindow>
        )}

        {isTermsAndConditionsOpen && (
          <FauxWindow onClose={() => setIsTermsAndConditionsOpen(false)}>
            <pre className="document-popup">
              <TermsOfUse />
            </pre>
          </FauxWindow>
        )}

        {isPrivacyPolicyOpen && (
          <FauxWindow onClose={() => setIsPrivacyPolicyOpen(false)}>
            <pre className="document-popup">
              <PrivacyPolicy />
            </pre>
          </FauxWindow>
        )}
      </div>
    </div>
  );
};
