import { mdxComponents } from "@onbeam/ui";
import React, { lazy, Suspense } from "react";
import { importMDX } from "mdx.macro";

const TermsOfUseDocument = lazy(() => importMDX("./terms-of-use.mdx"));

export const TermsOfUse = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <TermsOfUseDocument components={mdxComponents} />
  </Suspense>
);
