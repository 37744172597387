import React from "react";
import ReactDOM from "react-dom/client";
import { RootPage } from "./RootPage";
import { DocumentLayout } from "./DocumentLayout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsOfUse } from "./TermsOfUse";

import "@ethvault/iframe-provider-polyfill";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <RootPage />,
  },
  {
    path: "/privacy-policy",
    element: (
      <DocumentLayout>
        <PrivacyPolicy />
      </DocumentLayout>
    ),
    errorElement: <RootPage />,
  },
  {
    path: "/terms",
    element: (
      <DocumentLayout>
        <TermsOfUse />
      </DocumentLayout>
    ),
    errorElement: <RootPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
